const Calendar = ({ monthDateObject, attendanceDates }) => {
    const { month_days, approved_leaves_dates, days_absent, days_present, pending_dates, public_holidays } = attendanceDates;
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December"
    }
    let finalMonthDateObject = new Date(monthDateObject?.getUTCFullYear(), monthDateObject?.getMonth());

    let offset = finalMonthDateObject?.getDay();

    return (<div>
        {
            <>
                <h3 className="text-lg my-2 font-semibold">{months[monthDateObject.getMonth()] + " " + monthDateObject?.getUTCFullYear()} </h3>
                <div className="grid grid-cols-7 ">{days?.map((d, idx) => { return <><p key={idx} className="text-center bg-gray-200 p-1 mr-2 my-2 font-medium rounded-md">{d}</p></> })}</div>
                <div className="grid grid-cols-7">
                    {
                        Array(offset).fill(1)?.map(e => {
                            return <div className=" px-3"></div>
                        })
                    }
                    {
                        month_days?.map((d, idx) => {
                            return <div key={idx}
                                className={`p-3  flex items-center justify-center m-1 rounded-md
                            ${days_present.includes(d) && "bg-[color:var(--color1)] text-white"} 
                            ${days_absent.includes(d) && "bg-[color:var(--color13)] text-white"} 
                            ${pending_dates.includes(d) && " bg-[color:var(--color14)] text-white"} 
                            ${approved_leaves_dates.includes(d) && " bg-indigo-600 text-white"} 
                            ${public_holidays.includes(d) && "bg-[color:var(--color8)] text-white "}`}>
                                <p className="font-semibold">{d}</p>
                            </div>
                        })
                    }
                </div>
            </>
        }

    </div>);
}

export default Calendar;