import Modal from '@mui/material/Modal';
import DatePicker from "react-datepicker";
import { Controller, useForm } from 'react-hook-form'
import { config } from '../../config';
import { useRef } from 'react';
import useBulkUploadAttendance from '../../hooks/attendance/useBulkUploadAttendance';
const AttendanceModal = ({
    open,
    handleClose
}) => {
    const fileRef = useRef(null)
    const { control,handleSubmit,setValue, getValues, reset } = useForm({
        defaultValues: {
            month_year: '',
            excel_file: ''
        }
    })
    const {handleUploadAttendance,handlePost} = useBulkUploadAttendance({
        onSuccess: () => {
            handleClose()
            reset()
        }
    })
    const handleUploadFile = () => {
        fileRef.current.click()
    }
    const handleChangeFile = (e) => {
        let file = e.target.files[0]
        setValue('excel_file',file)
        const formData = getValues();
        handleUploadAttendance(formData)
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className=' flex items-center justify-center w-full h-full'>
                <div className='w-[40%] bg-white'>
                    <div className='px-4 py-2 border-b'>
                        <h1>Import Attendance</h1>
                    </div>
                    <div className="grid grid-cols-2 gap-x-5 px-4 py-6 border-b">
                        <div className=''>
                            <label className=' roboto  label'>Select Month/Year</label>
                            <Controller
                                control={control}
                                name='month_year'
                                rules={{
                                    required: 'Month Year is required'
                                }}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <>
                                        <DatePicker autoComplete='false' className='input w-full' dateFormat="MM/yyyy" showMonthYearPicker selected={value} onChange={onChange} />
                                        {error && (
                                            <small className=' text-red-700 text-xs mt-1'>{error.message || 'Error'}</small>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div>
                            <label className=' roboto  label'>Upload .xlsx file</label>
                            <button disabled={handlePost?.isLoading} onClick={handleSubmit(handleUploadFile)} type="button" className=" bg-[color:var(--color1)] text-white rounded px-4 py-2 w-[90%] block disabled:opacity-30">
                                {handlePost?.isLoading ? 'Uploading.....' : 'Upload'}
                            </button>
                            <input ref={fileRef} type="file" className='hidden' onChange={handleChangeFile}/>
                        </div>
                    </div>
                    <div className='px-4 py-3 flex items-center justify-between' >
                        <a className='text-blue-700 font-semibold text-sm underline' href={`${config.apiurl}excelUploadAttendanceSampleDownload`}>Download Sample .xlsx file</a>
                        <button className='py-2 px-4' onClick={handleClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AttendanceModal