import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import { useSearchParams } from 'react-router-dom';
import useRead from '../../hooks/useRead';
import Moment from 'react-moment';
import usePut from '../../hooks/usePut';
import Pagination from '../../components/pagination';
import PrimaryLoader from '../../components/common/PrimaryLoader';
import { Alert, Snackbar } from '@mui/material';
const WebCheckin = () => {
    const PunchTypes = {
        'punch_in': 'Punch In',
        'punch_out': 'Punch Out'
    }
    const punchApproveTypes = [
        {
            name: 'Approve',
            value: '1'
        },
        {
            name: 'Reject',
            value: '0'
        }
    ]
    const [searchParams, setSearchParams] = useSearchParams();
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    let initialFilter = {
        search_query: searchParams.get('search_query') || '',
        limit: 20,
        page_number: searchParams.get('page_number') || 0
    }

    const [checkInData, setCheckInData] = useState([])
    const { list, paramsObject, setGetListParams } = useRead({
        url: 'checked_in_user_list',
        initialData: initialFilter,
        onSuccess: (res) => {
            setCheckInData(res.data)
        }
    })

    const statusChange = usePut({
        url: 'approve_punch_details',
        onSuccess: () => {
            setSuccess(true)
        },
        onError: () => {
            setError(true)
        }
    })
    const handleSearch = (e) => {
        let name = e.target.name
        let value = e.target.value
        let data = {
            ...paramsObject,
            [name]: value
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    const handleChangeStatus = (e, id, index) => {
        let params = {
            approval_status: e.target.value,
            id
        }
        let data = checkInData
        data[index]['approval_status'] = e.target.value
        setCheckInData(checkInData)
        statusChange.handleAuth.mutate(params)
    }
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            page_number: val.selected
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    return (
        <Layout>
            {list.isLoading &&
                <div className=' fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-[#ffffffc7]'>
                    <PrimaryLoader />
                </div>}
            <div className="mb-12">
                <div className=" container">
                    <div className='flex items-center justify-between px-5'>
                        <div className=' w-[400px]'>
                            <input type="text" placeholder='Search By Name...' name='search_query' className='input' value={paramsObject.search_query} onChange={handleSearch} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="max-w-full overflow-x-auto px-4 mt-8">
                        <table className="table-auto w-full">
                            <thead>
                                <tr className=" bg-[color:var(--color1)] text-center">
                                    <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.No</th>
                                    <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Name</th>
                                    <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Email</th>
                                    <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Action Type</th>
                                    <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Time</th>
                                    <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4"> Approval Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {checkInData?.map((data, idx) => {
                                    const { name, email, id, type, punch_time, approval_status } = data
                                    return <tr key={idx} className='bg-gray-200 hover:bg-gray-100 text-sm'>
                                        <td className="text-center text-dark font-medium py-3 px-2 border-b border-l border-[#E8E8E8]">{idx + 1}</td>
                                        <td className="text-center text-dark font-medium py-3 px-2 border-b border-l border-[#E8E8E8]">{name}</td>
                                        <td className="text-center text-dark font-medium py-3 px-2 border-b border-[#E8E8E8]">{email}</td>
                                        <td className="text-center text-dark font-medium py-3 px-2 border-b border-[#E8E8E8]">{PunchTypes[type]}</td>
                                        <td className="text-center text-dark font-medium py-3 px-2 border-b border-[#E8E8E8]">
                                            <Moment date={punch_time} unix format='llll' />
                                        </td>
                                        <td className="text-center text-dark font-medium py-3 px-2 border-b border-[#E8E8E8]">
                                            <div>
                                                <select onChange={(e) => handleChangeStatus(e, id, idx)} value={approval_status} className='input'>
                                                    {punchApproveTypes.map((item, idx) => {
                                                        return <option key={idx} value={item.value}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='px-5 mt-5'>
                        <Pagination
                            currentPage={+paramsObject.page_number}
                            lengthofItems={list?.data?.total_count}
                            limit={+paramsObject.limit}
                            onPageChange={handlePageClick}
                        />
                    </div>
                </div>
            </div>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Status Updated Successfully
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={error} autoHideDuration={6000} onClose={() => setError(false)}>
                <Alert onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
                    There was an error while retrieving the data; please try again later.
                </Alert>
            </Snackbar>
        </Layout>
    )
}

export default WebCheckin