import { XIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'
import Pagination from '../pagination'

const EmployeeAssignModule = ({
    onClose,
    options,
    selected,
    onChange,
    nameKey = 'name',
    onPageChange,
    onSearch,
    paginationData={
        total_data:'',
        limit: '',
        currentPage:'',
    },
    searchValue
}) => {
    const [selectedValues, setSelectedValues] = useState(selected)
    const [selectedTab, setSelectedTab] = useState(0)
    const handleCheckValue = (obj) => {
        let { id } = obj
        let dupicateSelectedValue = selectedValues
        if (selectedValues.some(x => x.id === id)) {
            let newValue = dupicateSelectedValue.filter(x => x.id !== id)
            setSelectedValues(newValue)
            dupicateSelectedValue = newValue
        } else {
            let newData = [...dupicateSelectedValue, obj]
            dupicateSelectedValue = newData
            setSelectedValues((prevData) => [...prevData, obj])
        }
        onChange(dupicateSelectedValue)
    }
    return (
        <div className='flex h-full'>
            <div className='w-[20%] bg-gray-100'>
                <ul>
                    {Object.keys(options).map((item, itemIdx) => {
                        return <li key={itemIdx}>
                            <button onClick={() => setSelectedTab(itemIdx)} className={`py-2 px-4 font-semibold w-full capitalize ${selectedTab === itemIdx ? 'bg-cyan-100 text-cyan-700' : ''}`}>{item}</button>
                        </li>
                    })}
                </ul>
            </div>
            <div className='w-[80%] relative'>
                <div className='flex items-center space-x-5 border-b border-gray-100 py-4 px-4'>
                    <div className='flex-1'>
                        <input type="search" value={searchValue} onChange={onSearch} className='input' placeholder='Search' />
                    </div>
                    <div className='w-6 h-6' onClick={onClose}>
                        <XIcon className='w-full h-full cursor-pointer' />
                    </div>
                </div>
                <div className='h-full overflow-y-auto py-4 px-4'>
                    <div className='flex gap-3 flex-wrap'>
                        {Object.values(options)[selectedTab]?.map((value, valueIdx) => {
                            const { [nameKey]: name, id } = value
                            return <label key={valueIdx} className='border border-gray-300 rounded-md px-4 py-2 space-x-2 inline-block cursor-pointer'>
                                <input type="checkbox" checked={selectedValues.some(x => x.id === id)} onChange={() => handleCheckValue(value)} />
                                <span className='text-sm'>{name}</span>
                            </label>
                        })}
                    </div>
                <div className='px-5 mt-5 mb-20'>
                    <Pagination
                        currentPage={+paginationData.currentPage}
                        lengthofItems={+paginationData.total_data}
                        limit={+paginationData.limit}
                        onPageChange={onPageChange}
                    />
                </div>
                </div>
            </div>
        </div>

    )
}

export default EmployeeAssignModule