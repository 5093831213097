import { EyeIcon, XIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout/Layout'
import { getLeaveAppliactionList } from '../../api/employeeDetails'
import Modals from '../../components/modal/Modals'
import Moment from 'react-moment';
import ZoomImage from '../../components/ZoomImage'
import { verifyLeaveApplication } from '../../api/leaveApplicationForm'
import spinner from '../../assets/images/spinner.gif'

function LeaveApplied() {
   const [leaveList, setLeaveList] = useState([])
   const [singleLeaveData, setSingleLeaveData] = useState({})
   const [isOpenVerificationModal, setIsOpenVerificationModal] = useState(false)
   const [isFetchingData, setIsFetchingData] = useState(true)
   const [isLoading, setIsLoading] = useState(false)
   useEffect(() => {
      handleGetLeaveAppliactionList()
   }, [])

   const handleGetLeaveAppliactionList = () => {
      setIsFetchingData(true)
      getLeaveAppliactionList()
         .then((res) => {
            const { status, data } = res
            if (status === 200) {
               setLeaveList(data)
               setIsFetchingData(false)
            }
         })
         .catch((err) => {
            console.error(err)
            setIsFetchingData(false)
         })
   }
   const handleGetSingleLeaveData = (id) => {
      setSingleLeaveData(leaveList.find(data => data.id === id))
      setIsOpenVerificationModal(true)
   }

   const handleVerifyLeave = (id, action) => {
      setIsLoading(true)
      verifyLeaveApplication(id, action)
         .then((res) => {
            if (res.status === 200) {
               handleGetLeaveAppliactionList()
               setIsOpenVerificationModal(false)
               setIsLoading(false)
            }
         })
         .catch((err) => {
            console.log(err)
            setIsLoading(false)
         })
   }
   return (
      <>
         <Layout>
            {isFetchingData ? <div className=' w-16 h-16 mx-auto my-20'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className=" bg-[color:var(--color3)]">
               <div className=" container">
               </div>
               {leaveList.length > 0 ? <div class="max-w-full overflow-x-auto px-4 mt-8">
                  <table class="table-auto w-full">
                     <thead>
                        <tr class=" bg-[color:var(--color1)] text-center">
                           <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-4 px-3 lg:px-4 border-l border-transparent">User Name</th>
                           <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-4 px-3 lg:px-4 border-r border-transparent">Type of leave</th>
                           <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-4 px-3 lg:px-4 border-r border-transparent">Status</th>
                           <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-4 px-3 lg:px-4 border-r border-transparent">Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        {leaveList?.map((data, index) => {
                           const { employee_name, type_of_leave, id, status } = data
                           return <tr key={index}>
                              <td class=" text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{employee_name}</td>
                              <td class="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-[#E8E8E8] capitalize">{type_of_leave} </td>
                              <td className=' text-center border-b border-[#E8E8E8]'>
                                 {status === '1' ? <span className='bg-green-700 text-green-200 px-2 py-1 rounded-full text-xs'>Approved</span>
                                    : status === '2' ? <span className='bg-yellow-700 text-yellow-200 px-2 py-1 rounded-full text-xs'>Pending</span>
                                       : <span className='bg-red-700 text-red-200 px-2 py-1 rounded-full text-xs'>Rejected</span>}
                              </td>
                              <td class="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-r border-[#E8E8E8]">
                                 <div className=" flex justify-center space-x-1">
                                    <div className=" cursor-pointer" onClick={() => handleGetSingleLeaveData(id)}>
                                       <EyeIcon className=' text-black w-6' />
                                    </div>
                                 </div>
                              </td>
                           </tr>
                        })
                        }
                     </tbody>
                  </table>
               </div> : <h4 className='text-center font-semibold text-2xl text-gray-700'>No Leaves To Approve.</h4>}
            </div>}
            <Modals show={isOpenVerificationModal}>
               <div className=' divide-y'>
                  <div className="modalHead pb-3">
                     <div className='flex items-center justify-between'>
                        <h2 className='text-xl font-semibold'>Leave Verification</h2>
                        <div className='w-6 h-6 cursor-pointer' onClick={() => setIsOpenVerificationModal(false)}>
                           <XIcon className='w-full h-full' />
                        </div>
                     </div>
                  </div>
                  <div className="modalBody py-3 space-y-4">
                     <div>
                        <label htmlFor="employeeName" className='label'>Employee Name : </label>
                        <span className='text-lg font-semibold'> {singleLeaveData?.employee_name}</span>
                     </div>
                     <div>
                        <label htmlFor="leave_dates" className='label'>Leave Dates : </label>
                        <span className='text-lg font-semibold inline-block mx-2 bg-gray-300 px-4 py-2 rounded'>
                           <span> {singleLeaveData?.type_of_leave === 'CL' ? 'On : ' : 'From : '} </span>
                           <small><Moment unix format='DD/MM/yyyy'>{singleLeaveData?.leave_from}</Moment></small>
                        </span>
                        {singleLeaveData?.type_of_leave === 'CL' ? null : 
                        <span className='text-lg font-semibold inline-block ml-2 bg-gray-300 px-4 py-2 rounded'>
                           <span> To : </span>
                           <small>
                              {singleLeaveData?.leave_to !== '0' ? <Moment unix format='DD/MM/yyyy'>{singleLeaveData?.leave_to}</Moment> : '---'}
                           </small>
                        </span>}
                     </div>
                     <div>
                        <label htmlFor="employeeName" className='label'>Reason for leave : </label>
                        <span className='text-lg font-semibold'>{singleLeaveData?.reason}</span>
                     </div>
                     {(singleLeaveData?.type_of_leave === 'SL' || singleLeaveData?.type_of_leave === 'other') &&
                        (singleLeaveData?.file_list && singleLeaveData?.file_list.length > 0) && <div className='Medical Certificate'>
                           <label htmlFor="employeeName" className='label'>Medical Certificate : </label>
                           <div className='grid grid-cols-4 gap-4'>
                              {singleLeaveData?.file_list.map((src, idx) => {
                                 const { file_name, document_media_type } = src
                                 return <>
                                    {
                                       document_media_type === 'pdf' ? <a href={file_name} target="_blank" rel="noopener noreferrer" className=' text-center py-2 px-2 rounded bg-gray-400 text-gray-700'>View PDF</a> : <ZoomImage key={idx} className='h-full'>
                                          <img src={file_name} alt="dummy" className='border-2 border-black w-full h-full' />
                                       </ZoomImage>
                                    }
                                 </>

                              })}
                           </div>
                        </div>}
                  </div>

                  <div className="modalFoot pt-3">
                     {isLoading ? <div className='w-8 h-8 mx-auto'>
                        <img src={spinner} alt="spinner" className='w-full h-full' />
                     </div> : <div className=' space-x-4'>
                        <button className='px-8 py-2 rounded bg-[color:var(--color1)] text-white' onClick={() => handleVerifyLeave(singleLeaveData?.id, 'APPROVE')}>Verify</button>
                        <button className='px-8 py-2 rounded border' onClick={() => handleVerifyLeave(singleLeaveData?.id, 'REJECT')}>Reject</button>
                     </div>}
                  </div>
               </div>
            </Modals>
         </Layout>
      </>
   )
}

export default LeaveApplied