import React, { useRef, useState } from 'react'
import _, { set } from 'lodash'
import Layout from '../../components/layout/Layout'
import { Link } from "react-router-dom";
import Modals from '../../components/modal/Modals';
import spinner from '../../assets/images/spinner.gif'
import DialogMessage from '../../components/DialogMessage'
import useReadWithGet from '../../hooks/read/useReadWithGetMethod'
import AddressForm from './EmployeeForm/AddressForm'
import BankDetailsForm from './EmployeeForm/BankDetailsForm'
import WorkDetails from './EmployeeForm/WorkDetails'
import PersonalDetailForm from './EmployeeForm/PersonalDetailForm'
import { postEmployeeDetails } from '../../api/employeeDetails';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
function AddEmployeeDetails() {
    const navigate = useNavigate();
    const personalDetailRef = useRef(null)
    const addressDetailRef = useRef(null)
    const bankDetailRef = useRef(null)
    const workDetailRef = useRef(null)
    let initialDepartmentState = {
        limit: 50,
        searchQuery: '',
    }
    const [empCreateError, setEmpCreateError] = useState('')
    const initialState = {
        statutory: {
            epf: {
                isEpf: false,
                pf_account_number: '',
                pf_uan_number: '',
                is_contribute_to_scheme: false
            },
            esi: {
                isEsi: false,
                esi_number: ''
            },
            isPt: false
        }
    }
    const [isLoading, setIsLoading] = useState(false)
    const [employeDetail, setEmployeDetail] = useState(initialState)
    const [isShowingCopiedModal, setIsShowingCopiedModal] = useState(false)
    const handleSubmitEmployeDetail = (e) => {
        e.preventDefault()
        let personalData = personalDetailRef.current.getPersonalData()
        let addressData = addressDetailRef.current.getAddressData()
        let bankData = bankDetailRef.current.getBankDetailData()
        let workData = workDetailRef.current.getWorkDetail()
        let data = {
            ...personalData,
            ...addressData,
            ...bankData,
            ...workData,
            ...employeDetail
        }
        postEmployeeDetails(data)
            .then((res) => {
                if (res.status === 200) {
                    setIsLoading(false)
                }
                toast.success('Employee Created Successfully')
                navigate(-1)
            })
            .catch((err) => {
                setIsLoading(false)
                if (err?.response?.data?.message === "Required Fields") {
                }
                setEmpCreateError(err?.response?.data?.message)
            })
    }
    const [departOption, setDepartOption] = useState([])
    useReadWithGet({
        url: "department_listing",
        initialData: initialDepartmentState,
        onSuccess: (res) => {
            let data = res.data.map(({ id, name }) => ({ value: id, name: name }))
            setDepartOption(data);
        }
    })
    const [statutoryFormVisiblity, setStatutoryFormVisiblity] = useState({
        isEpf: false,
        isEsi: false,
    })
    const handleChangeStatutoryComponent = (e) => {
        let checked = e.target.checked
        setStatutoryFormVisiblity(prev => {
            return {
                ...prev,
                [e.target.name]: checked
            }
        })
        let data = employeDetail.statutory
        if(e.target.name === 'isEpf'){
            data['epf']['isEpf'] = checked
        }else{
            data['esi']['isEsi'] = checked
        }
        setEmployeDetail({...employeDetail})
    }
    const handleChangeStatutoryForm = (e, key) => {
        let name = e.target.name
        let value = e.target.value
        let copyData = employeDetail
        copyData.statutory[key][name] = value
        setEmployeDetail({ ...employeDetail })
    }
    const handleChangeStatutoryCheckbox = (e, key) => {
        let name = e.target.name
        let value = e.target.checked
        let copyData = employeDetail
        if (key === 'isPf') {
            copyData.statutory[key][name] = value
        } else {
            copyData.statutory[name] = value
        }
        setEmployeDetail({ ...employeDetail })
    }
    return (
        <Layout>
            <Toaster/>
            <div className="">
                <div className=" container px-6">
                    <div className="py-4 text-start">
                        <Link to={"/employe-details"} className=" bg-[color:var(--color1)] text-white rounded py-2 px-5">Back</Link>
                    </div>
                    <form action="" method="POST" onSubmit={handleSubmitEmployeDetail}>
                        <small className=' px-4 py-4'><span className=' mandatory'>*</span> Fields are mandatory</small>
                        <div className=" ">
                            <>
                                <div className=' space-y-10'>
                                    <PersonalDetailForm ref={personalDetailRef} departOption={departOption} />
                                    <AddressForm ref={addressDetailRef}/>
                                    <BankDetailsForm ref={bankDetailRef}/>
                                    <WorkDetails ref={workDetailRef} />
                                    <div className=''>
                                        <div>
                                            <h1 className='mt-4 text-2xl font-semibold'>Statutory Components</h1>
                                            <small>Enable the necessary benefits and tax applicable for this employee.</small>
                                        </div>
                                        <div className=' space-y-4'>
                                            <div className=" grid grid-cols-1 gap-4 leaveAllot-section">
                                                <div className=" mt-4">
                                                    <div className=' space-x-2'>
                                                        <input type="checkbox" id='isEpf' name='isEpf' onChange={handleChangeStatutoryComponent} />
                                                        <label htmlFor='isEpf' className=' cursor-pointer'>Employees' Provident Fund</label>
                                                    </div>
                                                    {employeDetail.statutory.epf.isEpf &&
                                                        <div className='border-b border-gray-200 pb-4'>
                                                            <div className=" grid grid-cols-2 gap-4 leaveAllot-section">
                                                                <div className="">
                                                                    <label htmlFor="total_leave" className='label'>PF Account Number:</label>
                                                                    <input type="text" name='pf_account_number' value={employeDetail.statutory.epf.pf_account_number} onChange={(e) => handleChangeStatutoryForm(e, 'epf')} className='input disabled:cursor-not-allowed' placeholder='AA/AAA/0000000/000/0000000' />
                                                                </div>
                                                                <div className="">
                                                                    <label htmlFor="total_leave" className='label'>UAN:</label>
                                                                    <input type="text" name='pf_uan_number' value={employeDetail.statutory.epf.pf_uan_number} onChange={(e) => handleChangeStatutoryForm(e, 'epf')} className='input disabled:cursor-not-allowed' placeholder='000000000000' />
                                                                </div>
                                                            </div>
                                                        </div>}
                                                </div>
                                            </div>
                                            <div className=" grid grid-cols-1 gap-4 leaveAllot-section">
                                                <div className=" mt-4">
                                                    <label htmlFor="" className=' space-x-2 cursor-pointer'>
                                                        <input type="checkbox" name='isEsi' id='isEsi' onChange={handleChangeStatutoryComponent} />
                                                        <label htmlFor='isEsi' className=''>Employees' State Insurance</label>
                                                    </label>
                                                    {employeDetail?.statutory?.esi?.isEsi &&
                                                        <div className='border-b border-gray-200 pb-4'>
                                                            <div className=" grid grid-cols-2 gap-4 leaveAllot-section">
                                                                <div className="">
                                                                    <label htmlFor="total_leave" className='label'>ESI Insurance Number:</label>
                                                                    <input type="text" name='esi_number' value={employeDetail.statutory.esi.esi_number} onChange={(e) => handleChangeStatutoryForm(e, 'esi')} className='input disabled:cursor-not-allowed' placeholder='000000000000' />
                                                                </div>
                                                            </div>
                                                            <label htmlFor="" className=' space-x-2 mt-4 block'>
                                                                <small className=' text-gray-500'>Note: ESI deductions will be made only if the employee’s monthly salary is less than or equal to ₹21,000 </small>
                                                            </label>
                                                        </div>}
                                                </div>
                                            </div>
                                            <div className=" grid grid-cols-1 gap-4 leaveAllot-section">
                                                <div className=" mt-4">
                                                    <label htmlFor="" className=' space-x-2 cursor-pointer'>
                                                        <input type="checkbox" name='isPt' id='isPt' checked={employeDetail.statutory.isPt} onChange={(e) => handleChangeStatutoryCheckbox(e, 'isPt')} />
                                                        <label htmlFor='isPt' className=''>Professional Tax</label>
                                                    </label>
                                                    <label htmlFor="" className=' space-x-2 block'>
                                                    <small className=' text-gray-500'>
                                                    Note: Professional Tax deductions <br />
                                                    Male employee if salary between &#8377; 7,500 - &#8377; 10,000
                                                    - (&#8377;175) will be deducted if salary greater then &#8377; 10,000 - (&#8377;200) will be deducted <br />
                                                    Female employee if salary between &#8377; 7,500 - &#8377; 25,000
                                                    - (&#8377;0) will be deducted if salary greater then &#8377; 25,001 - (&#8377;200) will be deducted <br />
                                                </small>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isLoading ? <div className=' w-14 h-14 mx-auto my-8'>
                                    <img src={spinner} alt="spinner" className='w-full h-full' />
                                </div> : <div className=" text-center my-8">
                                    <button type='submit' className=' bg-[color:var(--color1)] hover:bg-[color:var(--color12)] text-white py-3 px-20 rounded-full'>Submit</button>
                                </div>}
                            </>
                        </div>
                    </form>
                </div>
            </div>
            <Modals show={isShowingCopiedModal}>
                <div>
                    <p className=' roboto'>Your Data is successfully copied</p>
                    <div className=" mt-12">
                        <p onClick={() => { setIsShowingCopiedModal(false) }} className='w-max  bg-[color:var(--color1)] text-white px-5 py-2 rounded hover:cursor-pointer'>Ok</p>
                    </div>
                </div>
            </Modals>
            <DialogMessage open={empCreateError} type={"Error"} message={empCreateError === "Required Fields" ? "Some fields are missing" : empCreateError} handleClose={() => setEmpCreateError('')} />
        </Layout>
    )
}

export default AddEmployeeDetails