import React from 'react'
import './navbardropdown.css'
import { Link } from 'react-router-dom'
import { SwitchHorizontalIcon } from '@heroicons/react/solid'
import useClickOutside from '../../../hooks/useClickOutside'

const NavbarDropdown = ({ open, onClose, menuData }) => {
    const navbarElemRef = useClickOutside(() => {
        onClose()
    })
    return (
        <>
            {open &&
                <div ref={navbarElemRef} className='navbardropdown_container p-2 space-y-2'>
                    {menuData.map((menudata, menuIdx) => {
                        let { name, icon, slug } = menudata
                        return <Link key={menuIdx} to={slug} className=' flex items-center text-sm px-2 py-2 rounded space-x-3 bg-gray-100 text-gray-600'>
                            <span>{name}</span>
                        </Link>
                    })}
                </div>}
        </>
    )
}

export default NavbarDropdown