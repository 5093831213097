import React from 'react'
import useReadWithGet from '../../hooks/read/useReadWithGetMethod'
import { useParams, useSearchParams } from 'react-router-dom'
import DatePicker from "react-datepicker";

const LeaveBalance = () => {
    const { id } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const leaveBalanceInitialState = {
        month_year_leave_balance: searchParams.get('month_year_leave_balance') ? new Date(searchParams.get('month_year_leave_balance')) : new Date(),
        user_id: id
    }
    const { list: leaveCountData, paramsObject, setGetListParams } = useReadWithGet({
        url: 'getUserLeaveCount',
        initialData: leaveBalanceInitialState
    })
    const handleDate = (value) => {
        let data = {
            ...paramsObject,
            'month_year_leave_balance': value,
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    return (
        <div className='leave-balance my-10'>
            <div>
                <h1 className=' text-center font-semibold text-lg py-3 px-4 bg-[color:var(--color1)] text-white'>Leave Balance</h1>
                <div className='flex items-center justify-between mt-4'>
                    <div className=' space-y-4'>
                        <div className=' space-x-8 flex items-center'>
                            <p className=' font-semibold text-lg py-2 bg-orange-100 text-orange-700 px-5 rounded'>Opening Leave Balance: {leaveCountData?.data?.data.leave_balance?.opening_leave_balance}</p>
                            <p className=' font-semibold text-lg py-2 bg-orange-100 text-orange-700 px-5 rounded'>Closing Leave Balance: {leaveCountData?.data?.data.leave_balance?.closing_leave_balance}</p>
                        </div>
                        <div className=' space-x-8 flex items-center'>
                            <p className=' font-semibold text-lg py-2 bg-blue-100 text-blue-700 px-5 rounded'>Opening Days Balance: {leaveCountData?.data?.data.leave_balance?.opening_balance_no_days}</p>
                            <p className=' font-semibold text-lg py-2 bg-blue-100 text-blue-700 px-5 rounded'>Closing Days Balance: {leaveCountData?.data?.data.leave_balance?.closing_balance_no_days}</p>
                        </div>
                        <div className=' space-x-8 flex items-center'>
                            <p className=' font-semibold text-lg py-2 bg-green-100 text-green-700 px-5 rounded'>Opening OT Hours Balance: {leaveCountData?.data?.data.leave_balance?.opening_balance_ot_hours}</p>
                            <p className=' font-semibold text-lg py-2 bg-green-100 text-green-700 px-5 rounded'>Closing OT Hours Balance: {leaveCountData?.data?.data.leave_balance?.closing_balance_ot_hours}</p>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <span className=' text-gray-700 text-sm block w-full'>Leave Balance For Month</span>
                        <DatePicker required name='month_and_year' autoComplete='false' className='input' dateFormat="MM/yyyy" showMonthYearPicker selected={paramsObject?.month_year_leave_balance} onChange={(value) => handleDate(value, 'month_year_leave_balance')} />
                    </div>
                </div>
                <div className='grid grid-cols-3 gap-4 mt-4'>
                    <table className='w-full'>
                        <tbody>
                            <tr>
                                <td className='font-bold border py-3 px-4'>Basic In Month</td>
                                <td className='border py-3 px-4'>{leaveCountData?.data?.data.leave_balance.basic_accured_in_month || '----'}</td>
                            </tr>
                            <tr>
                                <td className='font-bold border py-3 px-4'>Last utilized</td>
                                <td className='border py-3 px-4'>{leaveCountData?.data?.data.leave_balance.basic_accured_utilised || '----'}</td>
                            </tr>
                            <tr>
                                <td className='font-bold border py-3 px-4'>Opening Balance</td>
                                <td className='border py-3 px-4'>{leaveCountData?.data?.data.leave_balance.basic_accured_opening_balance || '----'}</td>
                            </tr>
                            <tr>
                                <td className='font-bold border py-3 px-4'>Closing Balance</td>
                                <td className='border py-3 px-4'>{leaveCountData?.data?.data.leave_balance.basic_accured_closing_balance || '----'}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className='w-full'>
                        <tbody>
                            <tr>
                                <td className='font-bold border py-3 px-4'>Holiday OT in month</td>
                                <td className='border py-3 px-4'>
                                    {leaveCountData?.data?.data?.holiday_ot_leave_applicable
                                        ? <>
                                            {leaveCountData?.data?.data.leave_balance.holiday_ot_in_month || '----'}
                                        </>
                                        : 'Not Applicable'
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className='font-bold border py-3 px-4'>Holiday OT utilized</td>
                                <td className='border py-3 px-4'>
                                    {leaveCountData?.data?.data?.holiday_ot_leave_applicable
                                        ? <>
                                            {leaveCountData?.data?.data.leave_balance.holiday_ot_utilised || '----'}
                                        </>
                                        : 'Not Applicable'
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className='font-bold border py-3 px-4'>Holiday Opening Balance</td>
                                <td className='border py-3 px-4'>
                                    {leaveCountData?.data?.data?.holiday_ot_leave_applicable
                                        ? <>
                                            {leaveCountData?.data?.data.leave_balance.holiday_ot_opening_balance || '----'}
                                        </>
                                        : 'Not Applicable'
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className='font-bold border py-3 px-4'>Holiday Closing Balance</td>
                                <td className='border py-3 px-4'>
                                    {leaveCountData?.data?.data?.holiday_ot_leave_applicable
                                        ? <>
                                            {leaveCountData?.data?.data.leave_balance.holiday_ot_closing_balance || '----'}
                                        </>
                                        : 'Not Applicable'
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className='w-full'>
                        <tbody>
                            <tr>
                                <td className='font-bold border py-3 px-4'>Normal OT in month</td>
                                <td className='border py-3 px-4'>
                                    {leaveCountData?.data?.data?.normal_ot_leave_applicable
                                        ? <>
                                            {leaveCountData?.data?.data.leave_balance.normal_ot_in_month || '----'}
                                        </>
                                        : 'Not Applicable'
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className='font-bold border py-3 px-4'>Normal OT utilized</td>
                                <td className='border py-3 px-4'>
                                    {leaveCountData?.data?.data?.normal_ot_leave_applicable
                                        ? <>
                                            {leaveCountData?.data?.data.leave_balance.normal_ot_utilised || '----'}
                                        </>
                                        : 'Not Applicable'
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className='font-bold border py-3 px-4'>Normal Opening Balance</td>
                                <td className='border py-3 px-4'>
                                    {leaveCountData?.data?.data?.normal_ot_leave_applicable
                                        ? <>
                                            {leaveCountData?.data?.data.leave_balance.normal_ot_opening_balance || '----'}
                                        </>
                                        : 'Not Applicable'
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className='font-bold border py-3 px-4'>Normal Closing Balance</td>
                                <td className='border py-3 px-4'>
                                    {leaveCountData?.data?.data?.normal_ot_leave_applicable
                                        ? <>
                                            {leaveCountData?.data?.data.leave_balance.normal_ot_closing_balance || '----'}
                                        </>
                                        : 'Not Applicable'
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default LeaveBalance