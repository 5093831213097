import React, { useEffect, useState } from 'react'

const LikertView = ({ onChange, selectedData, values }) => {
    useEffect(() => {
        setWeekEndObjectState(values);
    }, [values])
    const [selectedValues, setSelectedValues] = useState(selectedData)
    const [weekEndObjectState, setWeekEndObjectState] = useState(values)
    const handleCheckWeekend = (e, week, weekDayIdx, isCheckbox) => {
        let name = e.target.name
        let value
        if(isCheckbox){
            value = e.target.checked
        }else{
            value = e.target.value
        }
        let duplicateWeekendObject = weekEndObjectState
        duplicateWeekendObject[week][weekDayIdx][name] = value
        setWeekEndObjectState({ ...duplicateWeekendObject })
        onChange(duplicateWeekendObject)
    }

    return (
        <div className='border border-gray-300 rounded mt-5'>
            <div className="grid grid-cols-7 grid-flow-row ">
                <div className="row-span-2 col-span-1 flex items-center justify-center border-r border-b border-gray-300 text-xl font-semibold">
                    Days
                </div>
                <div className='col-span-6 row-span-2'>
                    <div className="col-span-6 text-center py-2 border-b border-gray-300 font-semibold text-xl">Weeks</div>
                    <div className="col-span-6">
                        <div className='grid grid-cols-5'>
                            <div className=' text-center border-r border-b py-2 border-gray-300'>1st</div>
                            <div className=' text-center border-r border-b py-2 border-gray-300'>2nd</div>
                            <div className=' text-center border-r border-b py-2 border-gray-300'>3rd</div>
                            <div className=' text-center border-r border-b py-2 border-gray-300'>4th</div>
                            <div className=' text-center border-b py-2'>5th</div>
                        </div>
                    </div>
                </div>
            </div>
            {Object.keys(weekEndObjectState).map((weekName, weekIdx) => {
                return <div key={weekIdx} className="grid grid-cols-7 grid-flow-row">
                    <div className="col-span-1 capitalize flex items-center justify-center border-r border-b border-gray-300 font-semibold text-lg">
                        {weekName}
                    </div>
                    <div className='col-span-6'>
                        <div className='grid grid-cols-5'>
                            {weekEndObjectState[weekName].map((weekDaysDetail, weekDaysIdx) => {
                                const { id, isSelected, weekendDay } = weekDaysDetail
                                return <div key={weekDaysIdx} className=' text-center border-r border-b py-2 border-gray-300'>
                                    <div className='flex item-center space-x-4 px-4'>
                                        <input type="checkbox" name='isSelected' checked={isSelected} onChange={(e) => handleCheckWeekend(e, weekName, weekDaysIdx, true)} />
                                        <select disabled={!isSelected} className='input disabled:opacity-30' name='weekendDay' value={weekendDay} onChange={(e) => handleCheckWeekend(e, weekName, weekDaysIdx,false)}>
                                            <option value="full_day">Full Day</option>
                                        </select>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            })}
        </div>
    )
}

export default LikertView