import { ArrowLeftIcon } from '@heroicons/react/solid'
import { Drawer } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import spinner from '../../assets/images/spinner.gif'
import usePut from '../../hooks/usePut';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';

const processing_status = {
    '1': {
        color: 'bg-green-200 text-green-600',
        value: 'Attendance Processed'
    },
    '2': {
        color: 'bg-yellow-200 text-yellow-600',
        value: 'Attendance Processing'
    },
    '3': {
        color: 'bg-red-200 text-red-600',
        value: 'Attendance Not Processed'
    },
}
const EditAttendance = ({ open, onClose, id }) => {
    const [attendanceData, setAttendanceData] = useState({})
    const { handleAuth } = usePut({
        url: 'viewAttendance',
        onSuccess: (res) => {
            setAttendanceData(res.data.data)
        },
        onError: () => {
        }
    })
    const updateAttendance = usePut({
        url: 'saveandupdateAttendance',
        onSuccess: (res) => {
            toast.success('Attendance Updated Successfully')
            setTimeout(() => {
                onClose()
            },1500)
        },
        onError: () => {
            toast.error('There Was An Error While Updating The Attendance')
        }
    })
    useEffect(() => {
        if (open) {
            let data = {
                id
            }
            handleAuth.mutate(data)
        }
    }, [open])
    const handleChangeForm = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setAttendanceData((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const handleChangeCheckbox = (e) => {
        let value = e.target.checked;
        let name = e.target.name;
        setAttendanceData((prev) => {
            return {
                ...prev,
                [name]: value ? '1' : '0'
            }
        })
    }
    const handleChangeDate = (object) => {
        const {value,name} = object
        let data = moment(value).unix()
        setAttendanceData(prev => {
            return {
                ...prev,
                [name]: data
            }
        })
    }
    const handleSaveAttendance = (e) => {
        e.preventDefault()
        let processedData = {
            ...attendanceData,
            action: 'update'
        }
        updateAttendance.handleAuth.mutate(processedData)
    }
    return (
        <Drawer anchor={'right'} open={open} onClose={onClose}>
            <Toaster/>
            {handleAuth.isLoading
                ? <p>Loading.....</p>
                :
                <div className='h-full bg-white px-8' style={{ width: 'calc(100vw - 550px)' }}>
                    <div className='flex items-center justify-between  py-5 border-b border-gray-200 sticky z-40 top-0 bg-white'>
                        <div className='flex items-center space-x-3'>
                            <ArrowLeftIcon className=' text-gray-700 w-8 h-8 cursor-pointer' onClick={onClose} />
                            <p className='text-gray-700 text-3xl font-semibold'>Edit Attendance</p>
                        </div>
                        <div>
                            <span className={` px-4 py-1 font-semibold rounded-md ${processing_status[attendanceData?.processing_status]?.color}`}>
                                {processing_status[attendanceData?.processing_status]?.value}
                            </span>
                        </div>
                    </div>
                    <form method="post " className='divide-y' onSubmit={(e) => handleSaveAttendance(e, 'Save')}>
                        <div className="grid grid-cols-2 gap-4 py-4">
                            <div className="">
                                <label htmlFor="holiday_name" className='label'>In-Date:<span className='mandatory'>*</span></label>
                                {attendanceData?.in_date && <DatePicker onChange={(value) => handleChangeDate({ value, name: 'in_date' })} dateFormat="dd/MM/yyyy" selected={new Date(+attendanceData?.in_date * 1000)} name='date' className='input' />}
                            </div>
                            <div className="">
                                <label htmlFor="holiday_name" className='label'>Out-Date:<span className='mandatory'>*</span></label>
                                {attendanceData?.out_date && <DatePicker onChange={(value) => handleChangeDate({ value, name: 'out_date' })} dateFormat="dd/MM/yyyy" selected={new Date(+attendanceData?.out_date * 1000)} name='date' className='input' />}
                            </div>
                            <div className="">
                                <label htmlFor="in_time" className='label'>In Time:<span className='mandatory'>*</span></label>
                                <input type="time" name='in_time' onChange={handleChangeForm} value={attendanceData?.in_time} className='input' />
                            </div>
                            <div className="">
                                <label htmlFor="out_time" className='label'>Out Time:<span className='mandatory'>*</span></label>
                                <input type="time" name='out_time' onChange={handleChangeForm} value={attendanceData?.out_time} className='input' />
                            </div>
                            <div className="">
                                <div>
                                    <label htmlFor="out_time" className='label'>Pre-Shift OT</label>
                                    <p className='input' >
                                        {attendanceData?.pre_shift_ot} min
                                    </p>
                                </div>
                                <div className='mt-2'>
                                    <label className=' space-x-2'>
                                        <input type="checkbox" onChange={handleChangeCheckbox} name="approval_status_pre_shift_ot" id="" checked={attendanceData?.approval_status_pre_shift_ot === '1'} />
                                        <span>Approve Pre-Shift OT</span>
                                    </label>
                                </div>
                            </div>
                            <div className="">
                                <div>
                                    <label htmlFor="out_time" className='label'>Post-Shift OT</label>
                                    <p className='input'>
                                        {attendanceData?.post_shift_ot} min
                                    </p>
                                </div>
                                <div className='mt-2'>
                                    <label className=' space-x-2'>
                                        <input type="checkbox" onChange={handleChangeCheckbox} name='approval_status_post_shift_ot' checked={attendanceData?.approval_status_post_shift_ot === '1'} />
                                        <span>Approve Post-Shift OT</span>
                                    </label>
                                </div>
                            </div>
                            <div className="">
                                <label htmlFor="status" className='label'>Status:<span className='mandatory'>*</span></label>
                                <select className='input' onChange={handleChangeForm} name="present_status" value={attendanceData?.present_status}>
                                    <option value="">--Select Attendance--</option>
                                    <option value="P">Present</option>
                                    <option value="A">Absent</option>
                                </select>
                            </div>
                            {/* <div className="">
                            <label htmlFor="status" className='label'>Present Definition:</label>
                            <p className='input'>{attendanceData?.present_definition}</p>
                        </div> */}
                            <div className="">
                                <div>
                                    <label htmlFor="out_time" className='label'>Late Duration</label>
                                    <p className='input'>{attendanceData?.late_duration}</p>
                                </div>
                                <div className='mt-2'>
                                    <label className=' space-x-2'>
                                        <input type="checkbox" onChange={handleChangeCheckbox} name="late" id="" checked={attendanceData?.late === '1'} />
                                        <span>Mark Late</span>
                                    </label>
                                </div>
                            </div>
                            <div className="">
                                <div className='grid grid-cols-2 gap-x-3'>
                                    <div>
                                        <label htmlFor="out_time" className='label'>Total Working Hours</label>
                                        {<p className='input'>{attendanceData?.total_worked_hours}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="out_time" className='label'>Variable OT</label>
                                        <p className='input'>{attendanceData?.variable_ot}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="document-foot space-x-4 py-4">
                            {updateAttendance.handleAuth.isLoading ? <div className=' w-8 h-8 mx-auto'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className="text-center space-x-4">
                                <button className=' py-2 px-4 rounded font-semibold bg-[color:var(--color1)] text-white'>Save</button>
                                <button type='button' className=' py-2 px-4 rounded font-semibold border border-[color:var(--color1)]' onClick={onClose}>Cancel</button>
                            </div>}
                        </div>
                    </form>
                </div>
            }
        </Drawer>
    )
}

export default EditAttendance