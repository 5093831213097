import { LogoutIcon, MenuIcon, UserIcon,DocumentTextIcon, XIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import profile from '../assets/images/profile.jpg'

import "./header.css";
import Dropdown from './dropdown/Dropdown';
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { PROJECT_NAME, TOKEN } from '../config'
import { Drawer } from '@mui/material';






const cookies = new Cookies
function Header({ onClick }) {
    const [isShowDropDown, setIsShowDropDown] = useState(false)
    const navigate = useNavigate();

    const handleLogout = () => {
        cookies.remove(TOKEN, { path: '/' })
        cookies.remove("_am_role", { path: '/' })
        cookies.remove("_am_name", { path: '/' })
        navigate('/login');
    };
    const [name, setName] = useState('')
    useEffect(() => {
        setName(cookies.get("_am_name"))
    }, [])
    const [toggleDrawer, setToggleDrawer] = useState(false)
    return (
        <>
            <div className=" text-white bg-gray-500 lg:h-16 px-4 shadow sticky top-0 mb-4 z-40">
                <div className=" hidden lg:flex items-center justify-between h-full items">
                    <div className="cursor-pointer toggle" onClick={onClick}>
                        {/* <   MenuIcon className='w-8 text-white ' /> */}
                    </div>
                    <div className="relative">
                        <div onClick={() => setIsShowDropDown(!isShowDropDown)} className=" cursor-pointer flex items-center">
                            <img src={profile} alt="profile" className=' h-10 rounded-full' />
                            <p className=' roboto ml-4 font-semibold'>{name}</p>
                        </div>
                        {
                            isShowDropDown ? <Dropdown show={isShowDropDown} className={' top-14 right-[-4px] bg-[color:var(--color1)] px-4 py-3 rounded'}>
                                <div onClick={handleLogout}>
                                    <ul className=' space-y-4'>
                                        <li className='flex text-white cursor-pointer'>
                                            <LogoutIcon className=' w-4' />
                                            <span className='ml-2'>Logout</span>
                                        </li>
                                        {/* <li className='flex text-gray-700'>
                                        <UserIcon className='w-6'/>
                                        <span>Edit Profile</span>
                                    </li> */}
                                    </ul>
                                </div>
                            </Dropdown> : null
                        }

                    </div>
                </div>
                <div className='flex lg:hidden items-center justify-between py-2'>
                    <div>
                        <MenuIcon className='w-8 text-white' onClick={() => setToggleDrawer(true)} />
                    </div>
                    <div>
                        <p className=' text-center text-xl text-white font-bold roboto'><NavLink to={"/"}>{PROJECT_NAME}</NavLink></p>
                    </div>
                </div>
                <Drawer
                    anchor={'left'}
                    open={toggleDrawer}
                    onClose={() => setToggleDrawer(false)}
                >
                    <div className='h-full w-full relative overflow-hidden'>
                        <div className='w-6 h-6 absolute right-3 top-2' onClick={() => setToggleDrawer(false)}>
                            <XIcon className='w-6 h-6'/>
                        </div>
                        <div className='bg-[color:var(--color1)] mt-14 h-full'>
                            <div className='divide-slate-400 divide-y-[1px]'>
                                { <div className=" text-white text-lg roboto text-center">
                                    <NavLink to={'/'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} text-sm flex items-center px-7 py-3  space-x-3 `}>
                                        <UserIcon className='w-5 h-5' />
                                        <span>User Dashboard</span>
                                    </NavLink>
                                </div>}
                                { <div className=" text-white text-lg roboto text-center">
                                    <NavLink to={'/user'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} text-sm flex items-center px-7 py-3  space-x-3 `}>
                                        <UserIcon className='w-5 h-5' />
                                        <span>Attendance</span>
                                    </NavLink>
                                </div>}
                                {<div className=" text-white text-lg roboto text-center">
                                    <div className={`bg-[color:var(--color6)] text-sm flex items-center px-7 py-3  space-x-3 `} onClick={handleLogout}>
                                        <LogoutIcon className=' w-5 h-5' />
                                        <span>Logout</span>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </Drawer>
            </div>
        </>
    )
}

export default Header