import React from 'react'
import Layout from '../../components/layout/Layout'
import EmployeeTab from './EmployeeTab'
import useGet from '../../hooks/read/useGet'
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Moment from 'react-moment';
import Pagination from '../../components/pagination';

const AbscondedEmployee = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialEmployeeState = {
        page_number: searchParams.get('page_number') || 0,
        limit: searchParams.get('limit') || 10,
        search_query: searchParams.get('search_query') || ''
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scrolling animation
        });
    };
    const { list: adscondedEmployeeList, paramsObject, setGetListParams } = useGet({
        url: 'absconded_employees_list',
        initialData: initialEmployeeState,
        onSuccess: () => {
            scrollToTop()
        },
        onError: () => {
            toast.error('There Was An Error While Fetching The Data Please Try Again Later')
        }
    })
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            page_number: val.selected
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    const handleSearch = (e) => {
        let name = e.target.name
        let value = e.target.value
        let data = {
            ...paramsObject,
            [name]: value
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    return (
        <Layout>
            <Toaster />
            <div className="mb-12">
                <div className=" container">
                    <div className='flex items-center justify-between px-5'>
                        <div className=' w-[400px]'>
                            <input type="text" placeholder='Search By Name...' name='search_query' className='input' value={paramsObject.search_query} onChange={handleSearch} />
                        </div>
                    </div>
                </div>
                <EmployeeTab />
                {adscondedEmployeeList.data?.data.length <= 0
                    ? <div className='text-center'>

                        <p className=' text-gray-700 text-3xl mt-4'>No users to show</p>
                    </div>
                    : <>
                        <div class="max-w-full overflow-x-auto px-4 mt-8">
                            <table class="table-auto w-full">
                                <thead>
                                    <tr class=" bg-[color:var(--color1)] text-center">
                                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Name</th>
                                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Email</th>
                                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Joined Date</th>
                                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Absconded From</th>
                                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Absconded Days</th>
                                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">designation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        adscondedEmployeeList.data?.data?.map((data, index) => {
                                            const { employee_name, phone, email, id, date_of_joining, designation,absconded_from,number_of_days } = data
                                            return <tr key={index} className='bg-gray-200 hover:bg-gray-100 cursor-pointer'>
                                                <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-l border-[#E8E8E8]">{employee_name}</td>
                                                <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">{email}</td>
                                                <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">
                                                    <Moment format='LL' unix>
                                                        {date_of_joining}
                                                    </Moment>
                                                </td>
                                                <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">
                                                    <Moment format='LL' unix>
                                                        {absconded_from}
                                                    </Moment>
                                                </td>
                                                <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">
                                                    {number_of_days || '---'}
                                                </td>
                                                <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">
                                                    {designation}
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='px-5 mt-5'>
                            <Pagination
                                currentPage={+paramsObject.page_number}
                                lengthofItems={adscondedEmployeeList.data?.count}
                                limit={paramsObject.limit}
                                onPageChange={handlePageClick}
                            />
                        </div>
                    </>
                }
            </div>
        </Layout>
    )
}

export default AbscondedEmployee