const IS_REQUIRED = true
export const COMPANY_DETAILS = [
    {
        name: 'company_name',
        placeholder: 'Enter Company Name',
        label: 'Company Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'industry',
        placeholder: 'Enter Industry',
        label: 'Industry',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'company_email',
        placeholder: 'Enter Email',
        label: 'Email',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'company_password',
        placeholder: 'Enter Password',
        label: 'Password',
        type: 'password',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'Enter Valid Email!',
    },
    {
        name: 'phone_number',
        placeholder: 'Enter Phone Number',
        label: 'Phone Number',
        type: 'number',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'strength',
        placeholder: 'Enter Total Strength',
        label: 'Total Strength',
        type: 'number',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'short_code',
        placeholder: 'Enter Short Code',
        label: 'Company Short Code',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
        toolTipMessage: 'This code would be used to create employee id'
    },
]