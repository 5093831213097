import React, { useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import SelectSearch from 'react-select-search';
import { ArrowLeftIcon, DownloadIcon } from '@heroicons/react/solid';
import usePut from '../../../hooks/usePut';
import PrimaryLoader from '../../../components/common/PrimaryLoader';
import toast  from 'react-hot-toast';

const typeOption = [
    { value: 'advance', name: 'Advance' },
    { value: 'bonus', name: 'Bonus' }
]
const ReportUpload = ({ departOption, onClose }) => {
    const excel_file_ref = useRef(null)
    const initialState = {
        month_year: '',
        excel_file: '',
        type: '',
        department_id: ''
    }
    const [uploadForm, setUploadForm] = useState(initialState)
    const handleChangeDepartment = (value, name) => {
        setUploadForm((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleSubmitCsvForm = (e) => {
        e.preventDefault()
        excel_file_ref.current.click()
    }

    const uploadCsv = usePut({
        url: 'advanceExcelUpload',
        onSuccess: () => {
            toast.success('File Uploaded Successfully')
        },
        onError: () => {
            toast.error('There Was an error while uploading please try again later')
        }
    })
    const uploadFile = (e) => {
        let file = e.target.files[0]
        let data = {
            ...uploadForm,
            excel_file: file
        }
        uploadCsv.handleAuth.mutate(data)
    }
    return (
        <>
            {uploadCsv.handleAuth.isLoading &&
                <div className=' fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-[#ffffffc7]'>
                    <PrimaryLoader />
                </div>}
            <div className='bg-white w-[40%] mx-auto mt-8 rounded-md shadow-lg'>
                <div>
                    <div className=' border-b border-gray-100 px-5 flex items-center justify-between py-2'>
                        <div className='flex items-center space-x-1'>
                            <ArrowLeftIcon className='w-6 h-6 cursor-pointer' onClick={onClose} />
                            <h1 className=' text-xl font-semibold py-2'>Upload CSV</h1>
                        </div>
                        <a href="/advance.xlsx" download className='flex items-center space-x-2 bg-orange-100 rounded-md px-4 py-2'>
                            <DownloadIcon className='w-5 h-5' />
                            <span>Download Format</span>
                        </a>
                    </div>
                    <form onSubmit={handleSubmitCsvForm}>
                        <div className='px-4 space-y-4 py-4'>
                            <div className=''>
                                <label className=' roboto label'>Select Month/Year</label>
                                <DatePicker required name='month_and_year' autoComplete='false' selected={uploadForm.month_year} onChange={(value) => handleChangeDepartment(value, 'month_year')} className='input' dateFormat="MM/yyyy" showMonthYearPicker />
                            </div>
                            <div className="">
                                <label htmlFor="salary" className='label'>Select Department:</label>
                                <SelectSearch
                                    onChange={(value) => handleChangeDepartment(value, 'department_id')}
                                    search
                                    options={departOption}
                                    value={uploadForm.department_id}
                                    name="department"
                                    placeholder="Select department" />
                            </div>
                            <div className="">
                                <label htmlFor="salary" className='label'>Select Type:<span className='mandatory'>*</span></label>
                                <SelectSearch
                                    onChange={(value) => handleChangeDepartment(value, 'type')}
                                    search
                                    required
                                    options={typeOption}
                                    value={uploadForm.type}
                                    name="type"
                                    placeholder="Select department" />
                            </div>
                            <div>
                                <button className='px-4 py-2 rounded-md bg-yellow-200'>Upload File</button>
                                <input type="file" name="excel_file" onChange={(e) => uploadFile(e)} className='hidden' accept=".xlsx" ref={excel_file_ref} />
                            </div>
                            <small className=' text-xs text-gray-600'>Upload only the given file format to avoid system issues.</small>
                        </div>
                    </form>

                </div>

            </div>
        </>
    )
}

export default ReportUpload