import React from 'react'
import { Navigate } from 'react-router-dom'
import Cookies from "universal-cookie";
import NotAllowed from '../pages/errors/NotAllowed'
import {TOKEN} from '../config'
const cookies = new Cookies();

function ProtectedRoutes({ component: Component, ...restOfProps }) {
    let token = cookies.get(TOKEN);
    let role = cookies.get("_am_role");
    const { children,accessTo } = restOfProps
    if (!token) {
        return <Navigate to="/login" replace />;
    } else {
        if(accessTo === 'all'){
            return children;
        }else if(accessTo.includes(role)){
            return children;
        }else{
            return <NotAllowed/>;
        }
    }
}

export default ProtectedRoutes