import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import { PlusIcon } from '@heroicons/react/solid'
import AdvanceShiftEditing from './AdvanceShiftEditing'
import useReadWithGet from '../../hooks/read/useReadWithGetMethod'
import { formatTime } from '../../utility'
import usePut from '../../hooks/usePut'
import PrimaryLoader from '../../components/common/PrimaryLoader'
import Loader from '../../components/Loader'


const Shifts = () => {
    let initialData = {
        limit: 50,
        searchQuery: '',
    }
    const [selectedId, setSelectedId] = useState(null)
    const [isOpenAdvanceShift, setIsOpenAdvanceShift] = useState(false)
    const { handleAuth } = usePut({
        url: 'shift_edit',
        refreshUrl: 'shift_listing',
        onSuccess: () => {
            setIsOpenAdvanceShift(true)
        }
    })
    const handleClickedAdvanceShift = (id) => {
        let data = { id }
        setSelectedId(id)
        handleAuth.mutate(data)
    }
    const handleCreateShift = () => {
        setIsOpenAdvanceShift(true)
    }
    const { list } = useReadWithGet({
        url: "shift_listing",
        initialData,
    })
    return (
        <Layout>
            {list.isLoading &&
                <div className='flex items-center justify-center fixed w-full h-full top-0 left-0 bg-[#ffffffc9] z-50'>
                    <PrimaryLoader />
                </div>
            }
            <div className='p-5'>
                <div>
                    <button onClick={handleCreateShift} className=' rounded-md bg-color1 px-8 py-2 text-white'>
                        <div className='flex items-center justify-center space-x-2'>
                            <PlusIcon className='w-6 h-6' />
                            <span className=' font-semibold'>Add Shift</span>
                        </div>
                    </button>
                </div>
                <div className="grid grid-cols-3 gap-5 mt-5">
                    {list.data?.data.map((shiftData, shiftIdx) => {
                        const { id, shiftName, shiftInTime, shiftOutTime } = shiftData
                        return <div key={shiftIdx} className='w-full rounded-md border border-gray-300 p-4'>
                            <div>
                                <p className=''>
                                    <label className='text-gray-700 font-semibold text-lg'>Shift Name: </label>
                                    <span>{shiftName}</span>
                                </p>
                                <p>
                                    <label className='text-gray-700 font-semibold text-lg'>Shift Time: </label>
                                    <span className=''>
                                        <span className='mx-1'>
                                            {formatTime(shiftInTime)}
                                        </span>
                                        -
                                        <span className='mx-1'>
                                            {formatTime(shiftOutTime)}
                                        </span>
                                    </span>
                                </p>
                                <button disabled={handleAuth.isLoading} onClick={() => handleClickedAdvanceShift(id)} className=' px-6 py-2 bg-cyan-500 text-white rounded-md text-xs mt-5 disabled:opacity-50 disabled:cursor-not-allowed'>
                                    <div className=' flex items-center space-x-2'>
                                        <span>Edit Advance Shift</span>
                                        {selectedId === id && <Loader />}
                                    </div>
                                </button>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <AdvanceShiftEditing shiftData={handleAuth.data?.data?.data[0]} open={isOpenAdvanceShift} onClose={() => { setIsOpenAdvanceShift(false); setSelectedId(null) }} />
        </Layout>
    )
}

export default Shifts