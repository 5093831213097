import React from 'react'
import Moment from 'react-moment'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useRead from '../../../hooks/useRead'

const EmployeeSalaryList = () => {
    const navigate = useNavigate()
    const { id, username } = useParams()
    const [searchParams] = useSearchParams();
    const { list } = useRead({
        url: 'getEmployeeSalary',
        initialData: {
            employee_id: id
        }
    })
    const handleNavigateToEmployeeDetail = (id, username, salaryData) => {
        navigate(`/employee-salary/${id}?mode=${searchParams.get('mode')}`, {
            state: salaryData
        })
    }
    return (
        <>
            {list.isLoading
                ? <p className='text-center text-gray-500 mt-14 text-2xl'>Loading.....</p>
                :
                <>
                    {list.data?.data?.length > 0
                        ? <div className="max-w-full overflow-x-auto px-4 mt-8">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className=" bg-[color:var(--color1)] text-center">
                                        <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.No</th>
                                        <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Annual CTC</th>
                                        <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Monthly Pay</th>
                                        <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Effect From</th>
                                        <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Effect Till</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.data?.data?.map((salaryData, idx) => {
                                        const { annual_ctc, monthly_ctc, from_date, to_date } = salaryData
                                        return <tr key={idx} onClick={() => handleNavigateToEmployeeDetail(id, username, salaryData)} className=' cursor-pointer bg-gray-100 hover:bg-gray-200'>
                                            <td className="text-center text-dark font-medium text-base py-3 px-2 border-b border-l border-[#E8E8E8]">{idx + 1}</td>
                                            <td className="text-center text-dark font-medium text-base py-3 px-2 border-b border-l border-[#E8E8E8]">{annual_ctc}</td>
                                            <td className="text-center text-dark font-medium text-base py-3 px-2 border-b border-l border-[#E8E8E8]">{monthly_ctc}</td>
                                            <td className="text-center text-dark font-medium text-base py-3 px-2 border-b border-l border-[#E8E8E8]">
                                                <Moment unix format='ll'>
                                                    {from_date}
                                                </Moment>
                                            </td>
                                            <td className="text-center text-dark font-medium text-base py-3 px-2 border-b border-l border-[#E8E8E8]">
                                                {to_date === '0' || to_date === 0
                                                    ? '-----'
                                                    :
                                                    <Moment unix format='ll'>
                                                        {to_date}
                                                    </Moment>
                                                }
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        : <p className='text-gray-500 text-2xl text-center mt-10'>No Data Found</p>}
                </>
            }
        </>
    )
}

export default EmployeeSalaryList