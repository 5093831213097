import toast from "react-hot-toast";
import usePut from "../usePut"
import { throwError } from "../../utility/errorHandling";

const useBulkUploadSalary = ({
    onSuccess
}) => {
    const { handleAuth: handlePost } = usePut({
        url: 'excelUploadSalary',
        refreshUrl: 'employeelist',
        onSuccess: (res) => {
            onSuccess?.()
            toast.success('Salary uploaded successfully, please wait while refreshing the list.')
            window.location.reload()
        },
        onError: (err) => {
            // console.log(err);
            throwError(err)
        }
    })
    const handleUploadSalary = (data) => {
        handlePost.mutate(data)
    }
    return { handleUploadSalary, handlePost }
}

export default useBulkUploadSalary