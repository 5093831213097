import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { generatePassword } from '../../../utility'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modals from '../../../components/modal/Modals';
import SelectSearch from 'react-select-search';
import { getUserName } from '../../../api';

const IS_REQUIRED = true
const PersonalDetailForm = forwardRef(({ departOption, isEdit = false, personalData }, ref) => {
    const personalDetailInitialState = {
        username_prefix: '',
        username: '',
        password: '',
        department: '',
        first_name: '',
        last_name: '',
        DOB: '',
        gender: '',
        email: '',
        mobile_number: '',
        home_phone: '',
        pan_number: '',
        aadhar_number: '',
        department_id: ''
    }
    useImperativeHandle(ref, (() => ({
        getPersonalData: () => {
            return personalDetailState
        }
    })))

    const [personalDetailState, setPersonalDetailState] = useState(personalDetailInitialState)
    const [isShowingCopiedModal, setIsShowingCopiedModal] = useState(false)
    const handleSetEmployeDetail = (e) => {
        setPersonalDetailState(() => {
            return {
                ...personalDetailState,
                [e.target.name]: e.target.value
            }
        })
    }
    useEffect(() => {
        if(!isEdit){
            handleGetUserName()
        }
    }, [])
    const handleGetUserName = (role) => {
        getUserName(role)
            .then((res) => {
                if (res.status === 200) {
                    let data = {
                        ...personalDetailState,
                        username_prefix: '',
                        username_surfix: res?.data.username_surfix,
                        username: res?.data.username_surfix
                    }
                    setPersonalDetailState(data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const handleGeneratePassword = () => {
        var password = generatePassword({
            uppercase: true,
            lowercase: true,
            symbols: true,
            numbers: true
        })
        let data = {
            ...personalDetailState,
            password: password,
        }
        setPersonalDetailState(data)
    }
    const copyUrl = async () => {
        setIsShowingCopiedModal(true)
    }
    const handleChangeDepartment = (value) => {
        setPersonalDetailState((prev) => {
            return {
                ...prev,
                department: value
            }
        })
    }
    useEffect(() => {
        if (personalData) {
            Object.keys(personalDetailInitialState).forEach((key) => {
                setPersonalDetailState((prev) => {
                    return {
                        ...prev,
                        [key]: personalData[key]
                    }
                })
            })
        }
    }, [personalData])
    return (
        <>
            <div className=' space-y-3'>
                <div className=" grid grid-cols-2 gap-4  auth-section ">
                    <h1 className='mt-4 text-2xl font-semibold'>Personal Details</h1>
                </div>
                    {!isEdit && 
                    <div className=" grid grid-cols-2 gap-4  auth-section ">
                        <div className="">
                            <label htmlFor="salary" className='label'>Username:<span className='mandatory'>*</span></label>
                            <input type="text" name='username' className='input disabled:cursor-not-allowed' required={IS_REQUIRED} value={personalDetailState.username_prefix + personalDetailState.username_surfix} readOnly disabled placeholder='Username' />
                        </div>
                        <div>
                            <div className=' grid grid-cols-2 gap-4 items-end'>
                                <div className="">
                                    <label htmlFor="gross_salary" className='label'>Password</label>
                                    <input type="text" name='password' readOnly disabled className='input disabled:cursor-not-allowed' placeholder='Password' value={personalDetailState.password} />
                                </div>
                                <div className="">
                                    <label htmlFor="gross_salary" className='label'></label>
                                    <button type='button' onClick={handleGeneratePassword} className='px-5 py-2 rounded bg-[color:var(--color9)] mt-9 text-white'>Generate Password</button>
                                </div>
                            </div>
                            {/* {errorObject?.password && <p className='text-xs text-red-600 mt-2'>{personalDetailState?.password}</p>} */}
                        </div>
                        <div>
                            <CopyToClipboard onCopy={copyUrl} text={"Username: " + personalDetailState.username_prefix + personalDetailState.username_surfix + ", Password: " + personalDetailState.password}>
                                <button type='button' className='px-5 py-2 rounded bg-[color:var(--color9)] text-white'>Copy Username and Password</button>
                            </CopyToClipboard>
                        </div>
                    </div> }
                <div className=" grid grid-cols-2 gap-4  personalDetail-section">
                    <div className="">
                        <label htmlFor="first_name" className='label'>First Name:<span className='mandatory'>*</span></label>
                        <input type="text" name='first_name' required={IS_REQUIRED} value={personalDetailState.first_name} onChange={handleSetEmployeDetail} className='input' placeholder='Enter first name ' />
                    </div>
                    <div className="">
                        <label htmlFor="last_name" className='label'>Last Name:<span className='mandatory'>*</span></label>
                        <input type="text" name='last_name' required={IS_REQUIRED} value={personalDetailState.last_name} onChange={handleSetEmployeDetail} className='input' placeholder='Enter last name ' />
                    </div>
                    <div className="">
                        <label htmlFor="DOB" className='label'>Date Of Birth:<span className='mandatory'>*</span></label>
                        <input type="date" name='DOB' className='input' required={IS_REQUIRED} value={personalDetailState.DOB} onChange={handleSetEmployeDetail} placeholder='Enter DOB ' />
                    </div>
                    <div className="">
                        <p className='label'>Gender:<span className='mandatory'>*</span></p>
                        <div className=" flex gap-x-4" >
                            <div className=' space-x-2'>
                                <input type="radio" id='male' name='gender' value={'male'} checked={personalDetailState.gender === 'male'} onChange={handleSetEmployeDetail} />
                                <label htmlFor='male' className=' cursor-pointer'>Male</label>
                            </div>
                            <div className=' space-x-2'>
                                <input type="radio" id='female' name='gender' value={'female'} checked={personalDetailState.gender === 'female'} onChange={handleSetEmployeDetail} />
                                <label htmlFor='female' className=' cursor-pointer'>Female</label>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <label htmlFor="email" className='label'>Email:<span className='mandatory'>*</span></label>
                        <input type="text" name='email' className='input' required={IS_REQUIRED} value={personalDetailState.email} onChange={handleSetEmployeDetail} placeholder='Email' />
                    </div>
                    <div className="">
                        <label htmlFor="mobile_number" className='label'>Mobile Number:<span className='mandatory'>*</span></label>
                        <input type="tel" name='mobile_number' maxLength={10} required={IS_REQUIRED} className='input' value={personalDetailState.mobile_number} onChange={handleSetEmployeDetail} placeholder='Enter mobile number' />
                        {/* {errorObject?.mobile_number && <p className='text-xs text-red-600 mt-2'>{errorObject?.mobile_number}</p>} */}
                    </div>
                    <div className="">
                        <label htmlFor="home_phone" className='label'>Alternate Number:</label>
                        <input type="tel" name='home_phone' maxLength={10} className='input' value={personalDetailState.home_phone} onChange={handleSetEmployeDetail} placeholder='Alternate Number' />
                    </div>
                    <div className="">
                        <label htmlFor="pan_number" className='label'>Pan Number:<span className='mandatory'>*</span></label>
                        <input type="text" name='pan_number' className='input' required={IS_REQUIRED} value={personalDetailState.pan_number} onChange={handleSetEmployeDetail} placeholder='Pan number' />
                        {/* {errorObject?.pan_number && <p className='text-xs text-red-600 mt-2'>{errorObject?.pan_number}</p>} */}
                    </div>
                    <div className="">
                        <label htmlFor="pan_number" className='label'>Aadhar Number:<span className='mandatory'>*</span></label>
                        <input type="tel" maxLength={12} name='aadhar_number' className='input' required={IS_REQUIRED} value={personalDetailState.aadhar_number} onChange={handleSetEmployeDetail} placeholder='Aadhar number' />
                        {/* {errorObject?.aadhar_number && <p className='text-xs text-red-600 mt-2'>{errorObject?.aadhar_number}</p>} */}
                    </div>
                    <div className="">
                        <label htmlFor="salary" className='label'>Select Department:<span className='mandatory'>*</span></label>
                        <SelectSearch
                            onChange={handleChangeDepartment}
                            search
                            options={departOption}
                            value={isEdit ? +personalDetailState.department_id : +personalDetailState.department}
                            name="department"
                            placeholder="Select department" />
                    </div>
                </div>
            </div>
            <Modals show={isShowingCopiedModal}>
                <div>
                    <p className=' roboto'>Your Data is successfully copied</p>
                    <div className=" mt-12">
                        <p onClick={() => { setIsShowingCopiedModal(false) }} className='w-max  bg-[color:var(--color1)] text-white px-5 py-2 rounded hover:cursor-pointer'>Ok</p>
                    </div>
                </div>
            </Modals>
        </>
    )
})

export default PersonalDetailForm