import React, { useEffect, useState } from 'react'

const MultipleSelect = ({ options, onChange, selectedDepartment }) => {
    const [selected, setSelected] = useState([])
    const handleCheckValue = (e, item) => {
        let checked = e.target.checked
        if (checked) {
            setSelected(prev => {
                return [...prev, item]
            })
        } else {
            let filterData = selected.filter(x => x.id !== item.id)
            setSelected(filterData)
        }
    }
    useEffect(() => {
        onChange(selected)
    }, [selected])
    useEffect(() => {
        if (selectedDepartment?.length > 0) {
            setSelected(selectedDepartment)
        }
    }, [selectedDepartment])
    const selectAllHandler = (selectAll) => {
        if (selectAll) {
            setSelected([...options]); // Add all objects
        } else {
            setSelected([]); // Clear the selected objects array
        }
    };
    return (
        <div>
            <div className='flex gap-3 flex-wrap'>
                <label className='border border-gray-300 rounded-md px-4 py-2 space-x-2 inline-block cursor-pointer'>
                    <input type="checkbox" checked={selected.length === options.length} onChange={(e) => selectAllHandler(e.target.checked)} />
                    <span className='text-sm font-semibold'>Select All</span>
                </label>
                {options?.map((item) => {
                    const { id, name } = item
                    return <label key={id} className='border border-gray-300 rounded-md px-4 py-2 space-x-2 inline-block cursor-pointer'>
                        <input type="checkbox" checked={selected.some(x => x.id === id)} onChange={(e) => handleCheckValue(e, item)} />
                        <span className='text-sm'>{name}</span>
                    </label>
                })}
            </div>
        </div>
    )
}

export default MultipleSelect