import React, { useState } from 'react'
import Cookies from 'universal-cookie';
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { loginUser } from '../../api/auth';
import ambrosianLogo from "../../assets/images/renderly_blue.png"
import spinner from '../../assets/images/spinner.gif'
import { PasswordInput } from '../../components/PasswordInput/PasswordInput';
import { LOGO, PROJECT_NAME, TOKEN } from '../../config';


const cookies = new Cookies
function Login() {
    const [userData, setuserData] = useState({})
    const [error, setError] = useState(false)
    let token = cookies.get(TOKEN);
    const [isLoading, setIsLoading] = useState(false)
    let navigate = useNavigate()

    const handleSetUserData = (e) => {
        let value = e.target.value
        setuserData({
            ...userData,
            [e.target.name]: value
        })
    }
    const Submit = (e) => {
        setIsLoading(true)
        e.preventDefault();
        loginUser(userData)
            .then((res) => {
                if (res.status === 200) {
                    cookies.set(TOKEN, res.data.token, { path: '/' })
                    cookies.set("_am_role", res.data.role, { path: '/' })
                    cookies.set("_am_name", res.data.name, { path: '/' })
                    navigate("/", { replace: true });
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                setError(true)
            })

    }

    console.log()

    if (token) return <Navigate to="/" replace />
    return (
        <>
            <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-100">
                <div className="flex flex-col items-center justify-center w-full flex-1 lg:px-20 px-5">
                    <div className="bg-white rounded-2xl shadow-2xl flex flex-col-reverse lg:flex-row lg:w-2/3 max-w-4xl">
                        <div className="lg:w-3/5 py-5 lg:py-16 px-4 lg:px-9">
                            <p className=" text-3xl font-semibold mb-4 roboto">Login</p>
                            <form onSubmit={Submit} className=" space-y-4">
                                <div>
                                    <label for="email" className="label">Email or Username</label>
                                    <input type="text" required
                                        className="input"
                                        name="email" placeholder="Enter your email or username here" value={userData.email} onChange={handleSetUserData}></input>
                                </div>
                                <div>
                                    <label for="password" className="label">Password</label>
                                    <PasswordInput onChange={handleSetUserData} value={userData.password} name={'password'} placeholder={'Enter password'} />
                                </div>
                                {error && <div className='text-red-700 text-center'>
                                    <small>Email or password is incorrect</small>
                                </div>}
                                {/* <div className=" flex items-center justify-between">
                                    <div className="rememberMe">
                                        <input type="checkbox" name="rememberme" id="rememberme" />
                                        <label for="checkbox" className="roboto"> Remember me</label>

                                    </div>
                                    <div className="forgetpassword">
                                        <a href="" className=" hover:text-[color:var(--color9)] roboto"><span>Forget password</span></a>
                                    </div>
                                </div> */}
                                <div className='mt-2'></div>
                                {/* <Link to={"/forgot-password"} className='text-blue-600 underline text-sm'>Forgot Password?</Link> */}
                                {isLoading ? <div className=' w-8 h-8 mx-auto'>
                                    <img src={spinner} alt="spinner" className='w-full h-full' />
                                </div> : <div className="button">
                                    <button type="submit" className=" w-full py-2 bg-[color:var(--color1)] text-white hover:bg-[color:var(--color9)] rounded roboto">Login</button>
                                </div>}
                            </form>
                        </div>
                        <div className="lg:w-2/5   text-white rounded-r-2xl rounded-br-2xl lg:py-16 px-12 text-center flex flex-col items-center justify-center ">
                            <p className=" hidden lg:block roboto text-sl font-semibold text-[color:var(--color1)]">Welcome To <span className="font-extrabold">{PROJECT_NAME}</span></p>
                            <div className=" flex justify-center mt-8 mb-10 lg:mb-0">
                                <img src={LOGO} alt="logo" className=' object-contain' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login