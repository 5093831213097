import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

const IS_REQUIRED = true
const WorkDetails = forwardRef(({ workDetailData }, ref) => {
    const initialWorkDetailState = {
        qualification: '',
        education_certificates: [],
        work_experience: '',
        work_documents: [],
        designation: '',
        date_of_joining: '',
        employeeCode: '',
        probationDate: ''
    }
    useImperativeHandle(ref, (() => ({
        getWorkDetail: () => {
            return workDetailForm
        }
    })))
    const [workDetailForm, setWorkDetailForm] = useState(initialWorkDetailState)
    const handleSetEmployeDetail = (e) => {
        setWorkDetailForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleFileUpload = (e) => {
        setWorkDetailForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e
            }
        })
    }
    useEffect(() => {
        if (workDetailData) {
            Object.keys(initialWorkDetailState).forEach((key) => {
                setWorkDetailForm((prev) => {
                    return {
                        ...prev,
                        [key]: workDetailData[key],
                        education_certificates: [],
                        work_documents: []
                    }
                })
            })
        }
    }, [workDetailData])
    return (
        <div>
            <h1 className='mt-4 text-2xl font-semibold'>Work Details</h1>
            <div className=' grid grid-cols-3 gap-4'>
                <div className="">
                    <label htmlFor="qualification" className='label'>Educational Qualification : <span className='mandatory'>*</span></label>
                    <input type="text" name='qualification' className='input' required={IS_REQUIRED} value={workDetailForm.qualification} onChange={handleSetEmployeDetail} placeholder='Enter your qualification' />
                </div>
                <div className=" ">
                    <label htmlFor="education_certificates" className='label'>Education Certificates:</label>
                    <input type="file" multiple name='education_certificates' className='input border-none' onChange={(e) => handleFileUpload(e)} placeholder='Enter your qualification' />
                </div>
                <div className="">
                    <label htmlFor="work_experience" className='label'>Work Experience : <small>{`(In year)`}</small><span className='mandatory'>*</span></label>
                    <input type="text" name='work_experience' required={IS_REQUIRED} className='input' value={workDetailForm.work_experience} onChange={handleSetEmployeDetail} placeholder='Enter your Work Experience' />
                </div>
                <div className=" ">
                    <label htmlFor="work_documents" className='label'>Work Experience Documents:</label>
                    <input type="file" multiple name='work_documents' className='input border-none' onChange={(e) => handleFileUpload(e)} placeholder='Enter your work experience' />
                </div>
                <div className="">
                    <label htmlFor="designation" className='label'>Designation:<span className='mandatory'>*</span></label>
                    <input type="text" name='designation' className='input' required={IS_REQUIRED} value={workDetailForm.designation} onChange={handleSetEmployeDetail} placeholder='Designation' />
                </div>
                <div className="">
                    <label htmlFor="date_of_joining" className='label'>Date Of Joining:<span className='mandatory'>*</span></label>
                    <input type="date" name='date_of_joining' className='input' required={IS_REQUIRED} value={workDetailForm.date_of_joining} onChange={handleSetEmployeDetail} placeholder='Date of joining' />
                </div>
                <div className="">
                    <label htmlFor="Employee code" className='label'>Employee code:<span className='mandatory'>*</span></label>
                    <input type="text" name='employeeCode' className='input' value={workDetailForm.employeeCode} onChange={handleSetEmployeDetail} placeholder='Employee code' />
                </div>
                <div className="">
                    <label htmlFor="Probation date" className='label'>Probation date:<span className='mandatory'>*</span></label>
                    <input type="date" name='probationDate' className='input' value={workDetailForm.probationDate} onChange={handleSetEmployeDetail} />
                </div>
            </div>
        </div>
    )
})

export default WorkDetails