import React from 'react'
import Layout from '../../components/layout/Layout'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { useNavigate, useParams } from 'react-router-dom'
import useRead from '../../hooks/useRead'

const SingleResignedEmployeeDetails = () => {
    const navigate = useNavigate()
    const { id, username } = useParams()
    const { list } = useRead({
        url: 'employeedetails_edit',
        initialData: { id }
    })
    return (
        <Layout>
        </Layout>
    )
}

export default SingleResignedEmployeeDetails