import { EyeIcon, PencilAltIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { getEmployeeDetail } from "../../api/employeeDetails";
import Layout from "../../components/layout/Layout"
import Pagination from "../../components/pagination";
import PrimaryLoader from "../../components/common/PrimaryLoader";
import { useCallback } from "react";
import useGet from "../../hooks/read/useGet";
import toast, { Toaster } from "react-hot-toast";
import DownloadAttendanceReport from "./DownloadAttendanceReport";
import AttendanceModal from "../../components/attendance/AttendanceModal";

const Attendance = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams();
    const [employeeData, setemployeeData] = useState([])
    const [pageno, setPageNo] = useState(0)
    const [total_data, setTotalData] = useState(0)
    const [limit, setLimit] = useState(20)
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scrolling animation
        });
    };
    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 1000);
        };
    };

    const [search_query, setSearchQuery] = useState('')
    const optimizedFn = useCallback(debounce(() => getEmployeeList()), []);
    // const handleSearch = (e) => {
    //     setSearchQuery(e.target.value)
    //     optimizedFn()
    // }
    useEffect(() => {
        getEmployeeList()
    }, [pageno])
    const getEmployeeList = () => {
        setIsLoading(true)
        getEmployeeDetail(pageno, limit, search_query)
            .then((res) => {
                if (res.status === 200) {
                    setIsLoading(false)
                    setemployeeData(res.data.data)
                    setTotalData(res.data.count)
                    scrollToTop()
                }
            })
            .catch((err) => {
                setIsLoading(false)
                alert('Something went wrong, Please reload')
                console.error(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    // const handlePageClick = (val) => {
    //     setPageNo(val.selected)
    // }

    const initialEmployeeState = {
        page_number: searchParams.get('page_number') || 0,
        limit: searchParams.get('limit') || 10,
        search_query: searchParams.get('search_query') || ''
    }
    const { list: employeeList, paramsObject, setGetListParams } = useGet({
        url: 'employeelist',
        initialData: initialEmployeeState,
        onSuccess: () => {
            scrollToTop()
            // toast.success('Employees Fetched Successfully')
        },
        onError: () => {
            toast.error('There Was An Error While Fetching The Data Please Try Again Later')
        }
    })

    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            page_number: val.selected
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    const handleSearch = (e) => {
        let name = e.target.name
        let value = e.target.value
        let data = {
            ...paramsObject,
            [name]: value
        }
        setGetListParams(data)
        setSearchParams(data)
    }

    const [isOpenReportModal, setOpenReportModal] = useState(false)

    const [isImportModalOpen,setIsModalOpen] = useState(false)
    return <Layout>
        <Toaster />
        {isLoading ?
            <div className="fixed top-0 left-0 w-full h-full bg-[#ffffffbd] z-50 flex items-center justify-center">
                <PrimaryLoader />
            </div>
            : <>
                <div className="flex items-center justify-between px-4 mb-10">
                    <div className=' w-[400px]'>
                        <input type="search" placeholder='Search By Name...' name='search_query' className='input' value={paramsObject.search_query} onChange={handleSearch} />
                    </div>
                    <div className=" px-5 flex items-center justify-between space-x-3">
                        <button type="button" onClick={() => setOpenReportModal(true)} className=" text-blue-600 font-semibold text-xs">Download Report</button>
                        <button type="button" onClick={() => setIsModalOpen(true)} className=" border border-gray-600 rounded px-4 py-2 text-xs">Import Attendance</button>
                    </div>
                </div>
                {employeeList.data?.data.length <= 0 ? <div className='text-center my-10'><p className=' text-gray-700 text-3xl'>No Data To Show</p></div> :
                    <>

                        <div class="max-w-full overflow-x-auto px-4">
                            <table class="table-auto w-full">
                                <thead>
                                    <tr class=" bg-[color:var(--color1)] text-center">
                                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Name</th>
                                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Email</th>
                                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">User Type</th>
                                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Attendance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        employeeList.data?.data?.map((data, index) => {
                                            const { employee_name, email, id, type_of_employee } = data
                                            return <tr key={index}>
                                                <td class="text-center text-dark font-medium text-sm py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{employee_name}</td>
                                                <td class="text-center text-dark font-medium text-sm py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{email}</td>
                                                <td class="text-center text-dark font-medium text-sm py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] capitalize">{type_of_employee}</td>
                                                <td class="text-center text-dark font-medium text-sm py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                                                    <div className='flex items-center justify-center w-full'>
                                                        <Link to={`/view-attendance/${id}?employee=${employee_name}`} className='bg-[color:var(--color1)] text-white rounded px-4 py-2 flex items-center space-x-2 justify-center'>
                                                            <EyeIcon className='w-6 h-6' />
                                                            <span>View</span>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='px-5 mt-5 mb-10'>
                            <Pagination
                                currentPage={+paramsObject.page_number}
                                lengthofItems={employeeList.data?.count}
                                limit={+paramsObject.limit}
                                onPageChange={handlePageClick}
                            />
                        </div>
                    </>}
            </>}
        <DownloadAttendanceReport open={isOpenReportModal} handleClose={() => setOpenReportModal(false)} />
        <AttendanceModal open={isImportModalOpen} handleClose={() => setIsModalOpen(false)}/>
    </Layout>
}

export default Attendance