import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const SalaryTab = () => {
    const { pathname } = useLocation()
    return (
        <div className='px-5 border-b border-gray-200 py-2 mt-10 '>
            <ul className='flex items-center space-x-6 text-gray-600'>
                <li className={`${pathname === '/salary' && 'active'}`}>
                    <Link to={`/salary`} >Employees Salary</Link>
                </li>
                <li className={`${pathname === '/provident-fund' && 'active'}`}>
                    <Link to={`/provident-fund`} >Provident Fund (PF)</Link>
                </li>
                <li className={`${pathname === '/esic' && 'active'}`}>
                    <Link to={`/esic`}>ESIC</Link>
                </li>
                <li className={`${pathname === '/professional-tax' && 'active'}`}>
                    <Link to={`/professional-tax`}>Professional Tax (PT)</Link>
                </li>
                {/* <li className={`${pathname === '/loans' && 'active'}`}>
                    <Link to={`/loans`}>Loan</Link>
                </li> */}
                <li className={`${pathname === '/advance-and-bonus' && 'active'}`}>
                    <Link to={`/advance-and-bonus`}>Advance and Bonus</Link>
                </li>
            </ul>
        </div>
    )
}

export default SalaryTab