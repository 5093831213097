import axios from 'axios';
import { config } from '../config';

export default axios.create({
  baseURL: config.apiurl,
});

export const axiosPrivate = axios.create({
  baseURL: config.apiurl,
  headers: { 'Content-Type': 'application/json' },
  // withCredentials: true
});