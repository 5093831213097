import logo from '../src/assets/images/renderly_blue.png'
export const LOGO = logo
export const PROJECT_NAME = 'Renderly'
export const PROJECT_META_NAME = 'Renderly'
export const ENVIONMENT = 'development' //production | development
export const config = {
    apiurl: ENVIONMENT === 'production' ? "https://api-hr.payalsinghal.net/" : ' https://api-hrms-demo.renderly.in/',
    frontEndUrl: ENVIONMENT === 'production' ? '/' : '/'
}



export const TOKEN = ENVIONMENT ? `${PROJECT_META_NAME}_demo_dev_token` :  `${PROJECT_META_NAME}_demo_prod_token`

export const TAX = 175;
export const ABOVE_LIMIT_TAX = 200;
export const EXEED_MONTH_LIMIT = 300;
export const TDS = 10;
export const PERCENTILE = 100;
export const GST = "";

export const MIN_LEAVE_DATE_FOR_PL = 15;
export const MIN_LEAVE_DATE_FOR_CL = 5;

export const DISCRETIONAL_LEAVE_YEAR_IGNORE = 2022

