import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import { getMetaData } from "../api";
import spinner from '../assets/images/spinner.gif'
import { DownloadIcon } from '@heroicons/react/solid';
const cookies = new Cookies
function Dashboard() {
  const [role, setRole] = useState('')
  const [data,setData] = useState()
  const [isLoading,setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    setRole(cookies.get("_am_role"))
    getMetaData()
    .then((res) => {
      if(res.status === 200){
        setData(res.data)
        setIsLoading(false)
      }
    })
    .catch((err) => {
      console.log(err)
      setIsLoading(false)
    })
  }, [])
  return (
    <>
      <Layout>
        {isLoading ? <div className=" w-16 h-16 mx-auto my-8"><img src={spinner} alt="spinner" className="w-full h-full"/></div> :
        <div className="px-4">
          {role === 'employee' ?<> <div><div className="grid grid-cols-2 lg:grid-cols-4 gap-y-4 gap-x-4">
            <div className="bg-orange-700 text-white text-center py-5 rounded">
              <h3 className="text-lg font-semibold">Sick Leaves Available</h3>
              <h1 className=" text-2xl font-semibold">{data?.pending_sl_leave}</h1>
            </div>
            <div className="bg-orange-700 text-white text-center py-5 rounded">
              <h3 className="text-lg font-semibold">Casual Leaves Available</h3>
              <h1 className=" text-2xl font-semibold">{data?.pending_cl_leave}</h1>
            </div>
            <div className="bg-orange-700 text-white text-center py-5 rounded">
              <h3 className="text-lg font-semibold">Priviledge Leaves Available</h3>
              <h1 className=" text-2xl font-semibold">{data?.pending_pl_leave}</h1>
            </div>
          </div>
          <div className="my-4 space-x-4">
            {data?.appointment_letter && <a className="text-lg font-semibold p-5 bg-orange-700 text-white text-center inline-block rounded" href={data?.appointment_letter} download={true}>
              <DownloadIcon className="w-6 h-6 inline-block"/> <span className="inline-block">Appointment Letter</span>
            </a>}
            {data?.confirmation_letter && <a className="text-lg font-semibold p-5 bg-orange-700 text-white text-center inline-block rounded" href={data?.confirmation_letter} download={true}>
              <DownloadIcon className="w-6 h-6 inline-block"/> <span className="inline-block">Confirmation Letter</span>
            </a>}
          </div></div>
          </> : role === 'consultant' ? <div><div className="grid grid-cols-2 lg:grid-cols-4 gap-x-4">
          <div className="bg-orange-700 text-white text-center py-5 rounded">
              <h3 className="text-lg font-semibold">Other Leaves Available</h3>
              <h1 className=" text-2xl font-semibold">{data?.pending_ol_leave}</h1>
            </div>
            <div className="bg-orange-700 text-white text-center py-5 rounded">
              <h3 className="text-lg font-semibold">Discretionary Leaves Available</h3>
              <h1 className=" text-2xl font-semibold">{data?.pending_dl_leave}</h1>
            </div>
            
          </div>
          </div> : <div className="grid grid-cols-2 lg:grid-cols-4 gap-4"><div className="bg-orange-700 text-white text-center py-5 rounded">
            <Link to={'/leave-applied'}>
              <h3 className="text-lg font-semibold">Pending Leaves</h3>
              <h1 className=" text-2xl font-semibold">{data?.pending_leave}</h1>
            </Link>
          </div></div>}
        </div>}
      </Layout>
    </>
  );
}

export default Dashboard;
